// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const server = 'https://api-k-health-test.getxo.eus/';

export const environment = {
  production: false,
  apiUrl: `${ server }v1/`,
  toasterTimeout: 6000,
  printConsoleError: true,
  fileMaxSize: 12, // In MBs,
  pageElements: 10,
  getxoLogo: '/assets/images/logo.png',
  getxoMenu: '/assets/images/logo-menu.svg',
  loginLogo: '/assets/images/logoKimetHealth.png',
  menuLogo: '/assets/images/logoKimetHealth.png',
  notFoundImage: '/assets/images/404.svg',
  isGetxo: true,
  isMugiment: false,
  theme: 'theme-magenta'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
