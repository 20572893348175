import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ClientPlanBuilder } from '../../models/builder/client-plan.builder';
import { ClientBuilder } from '../../models/builder/client.builder';
import { Client } from '../../models/client';
import { ClientPlan } from '../../models/client-plan';
import { ClientsRequestInterface } from '../../models/request/clients-request.interface';

const addRoutineToClientPlanBody = (routineLevelHash: string, routineDate: number) => ({
  routineLevelHash,
  routineDate
});

const createClientPlanBody = (routineLevelHash: string) => ({
  routineLevelHash
});

const saveClientBody = (client: Client) => ({
  cif: client.cif,
  member: client.member,
  memberType: client.memberType,
  name: client.name,
  firstSurname: client.firstSurname,
  email: client.email,
  telephone: client.telephoneNumber,
  bornDate: client.borndate,
  sex: client.sex,
  originHash: client.origin?.hash,
  city: client.city,
  contact: client.contact,
  hearService: client.hearService,
  hearServiceOthers: client.hearServiceOthers,
});

@Injectable()
export class ClientApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}clients`;
  }

  addRoutineToClientPlan(clientPlanHash: string, routineLevelHash: string, routineDate: number): Observable<ClientPlan> {
    return this.httpClient.put(`${this.apiUrl}/plan/${clientPlanHash}`, addRoutineToClientPlanBody(routineLevelHash, routineDate))
      .pipe(
        map(ClientPlanBuilder.fromJson)
      );
  }

  createClientPlan(clientHash: string, routineLevelHash: string): Observable<ClientPlan> {
    return this.httpClient.post(`${this.apiUrl}/${clientHash}/plan`, createClientPlanBody(routineLevelHash))
      .pipe(
        map(ClientPlanBuilder.fromJson)
      );
  }

  createEmptyClientPlan(clientHash: string): Observable<ClientPlan> {
    return this.httpClient.post(`${this.apiUrl}/${clientHash}/empty-plan`, {})
      .pipe(
        map(ClientPlanBuilder.fromJson)
      );
  }

  getFilteredClients(request: ClientsRequestInterface): Observable<any> {
    let params = new HttpParams();
    params = params
      .append('name', request.name)
      .append('from', request.page)
      .append('telephone', request.telephoneNumber)
      .append('count', environment.pageElements.toString())
      .append('sort', request.sortField)
      .append('reverse', (!!request.reverse).toString());

    return this.httpClient.get(`${this.apiUrl}/filter`, { params })
      .pipe(
        map(this.mapClientListData)
      );
  }

  getClients() {
    return this.httpClient.get(`${this.apiUrl}`).pipe(map(ClientBuilder.fromList));
  }

  getClient(hash: string) {
    return this.httpClient.get(`${this.apiUrl}/${hash}`)
      .pipe(
        map(ClientBuilder.fromJson)
      );
  }

  getClientByPrescription(hash: string) {
    return this.httpClient.get(`${this.apiUrl}/by-prescription/${hash}`)
      .pipe(
        map(ClientBuilder.fromJson)
      );
  }

  getClientPlan(clientPlanHash: string): Observable<ClientPlan> {
    return this.httpClient.get(`${this.apiUrl}/plan/${clientPlanHash}`)
      .pipe(
        map(ClientPlanBuilder.fromJson)
      );
  }

  createClient(client: Client) {
    return this.httpClient.post(this.apiUrl, saveClientBody(client))
      .pipe(
        map(ClientBuilder.fromJson)
      );
  }

  updateClient(client: Client, hash: string) {
    return this.httpClient.put(`${this.apiUrl}/${hash}`, saveClientBody(client))
      .pipe(
        map(ClientBuilder.fromJson)
      );
  }

  updateClientProfileImage(file: File, hash: string){
    const postBody = new FormData();
    postBody.append('profileImage', file);
    return this.httpClient.put(`${this.apiUrl}/${hash}/update-client-profile-image`, postBody)
      .pipe(
        map(ClientBuilder.fromJson)
      );
  }

  sendLopd(email: string): Observable<Client> {
    return this.httpClient.get(`${this.apiUrl}/send-lopd/${email}`)
      .pipe(
        map(ClientBuilder.fromJson)
      );
  }

  deleteClient(hash: string) {
    return this.httpClient.delete(`${this.apiUrl}/${hash}`);
  }

  deleteRoutineFromClientPlan(clientPlanRoutineLevelHash: string) {
    return this.httpClient.delete(`${this.apiUrl}/plan/routine/${clientPlanRoutineLevelHash}`);
  }

  generateClientPlanPdf(clientPlanHash: string): Observable<ArrayBuffer> {
    let params = new HttpParams();
    params = params
      .append('theme', environment.theme)
      .append('isGetxo', environment.isGetxo);

    return this.httpClient.get(`${this.apiUrl}/plan/${clientPlanHash}/pdf`, { params, responseType: 'arraybuffer' });
  }

  private mapClientListData = (data: any) => {
    data.items = ClientBuilder.fromList(data.items);
    return data;
  };
}
